<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-04 09:08:11
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-19 13:32:20
 * @FilePath: \official_website\src\views\medium\medium.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 媒体中心 -->
<template>
  <div id="medium">
    <Header></Header>
    <div class="medium-content">
      <div class="news-header">
        <div class="line"></div>
        <div class="news-title">新闻资讯</div>
        <div class="news-english">News</div>
        <ul class="news-box">
          <li
            class="new-item"
            v-for="item in focusNews"
            :key="item.id"
            @click="tapArticle(item.id)"
          >
            <!-- :style="{ 'background-image': `url(${item.titleImage}` }" -->
            <div class="focus-img">
              <img :src="item.titleImage" alt="" />
            </div>
            <div class="title-box wow animate__animated animate__fadeInUp">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="time">{{ item.times }}</div>
            </div>
          </li>
          <!-- <li class="new-item" @click="tapArticle('/medium/focus1')">
            <div class="focus-img" :style="{'background-image':`url(${require(`@/assets/image/medium//keynote-1.png`)}`}"></div>
            <div class="title-box">
              <div class="title">
                品信生物祝贺江西省医学会病理学分会第48次学术年会顺利召开，“吉米娜”AI系统精彩亮相！
              </div>
              <div class="time">2023-04-03</div>
            </div>
          </li>
          <li class="new-item" @click="tapArticle('/medium/focus2')">
            <div class="focus-img" :style="{'background-image':`url(${require(`@/assets/image/medium//keynote-4.png`)}`}"></div>
            <div class="title-box">
              <div class="title">
                湖南省工信厅党组成员、总经济师熊琛率队赴品信生物调研
              </div>
              <div class="time">2023-04-16</div>
            </div>
          </li>
          <li class="new-item" @click="tapArticle('/medium/focus3')">
            <div class="focus-img" :style="{'background-image':`url(${require(`@/assets/image/medium//keynote-3.png`)}`}"></div>
            <div class="title-box">
              <div class="title">
                品信生物携全新产品亮相CACLP，火爆现场，精彩持续中...
              </div>
              <div class="time">2023-05-23</div>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="news-list">
        <div class="list-item" v-for="item in newsList" :key="item.id">
          <div class="left">
            <div class="tiems">
              <div class="day">{{ item.day }}</div>
              <div class="year-month">{{ item.year }} - {{ item.month }}</div>
            </div>
            <div class="list-conent wow animate__animated animate__fadeInUp">
              <div class="title" @click="tapArticle(item.id)">
                {{ item.title }}
              </div>
              <div class="subtitle">
                {{ item.summary }}
              </div>
            </div>
          </div>
          <!-- :style="{'background-image': `url(${item.titleImage}`,}" -->
          <div class="right">
            <img :src="item.titleImage" alt="" />
          </div>
        </div>
        <!-- <div class="list-item">
          <div class="left">
            <div class="tiems">
              <div class="day">30</div>
              <div class="year-month">2023-08</div>
            </div>
            <div class="list-conent">
              <div class="title" @click="tapArticle('/medium/mediumDetail1')">
                品信生物8月参展回顾
              </div>
              <div class="subtitle">
                由广东省医学会主办，病理学分会承办的2023年广东省医学会病理学学术年会于2023年8月25日-27日在佛山东平保利洲际酒店举办。<br />
                2023年8月25日-27日，由山东省医学会、山东省医学会病理学分会主办的“山东省医学会第二十五次病理学学术会议暨病理读片会”于2023年8月25-27日在山东省济宁市顺利召开。
              </div>
            </div>
          </div>
          <div
            class="right"
            :style="{
              'background-image': `url(${require(`@/assets/image/medium/news-5.png`)}`,
            }"
          ></div>
        </div>
        <div class="list-item">
          <div class="left">
            <div class="tiems">
              <div class="day">16</div>
              <div class="year-month">2023-08</div>
            </div>
            <div class="list-conent">
              <div class="title" @click="tapArticle('/medium/mediumDetail2')">
                关于发布推荐性卫生行业标准《县级综合医院设备配置标准》的通告
              </div>
              <div class="subtitle">
                卫健委发文！！！《县级综合医院设备配置标准》发布，品信生物全面助力县级综合医院病理科设备建设
              </div>
            </div>
          </div>
          <div
            class="right"
            :style="{
              'background-image': `url(${require(`@/assets/image/medium/news-6.png`)}`,
            }"
          ></div>
        </div>
        <div class="list-item">
          <div class="left">
            <div class="tiems">
              <div class="day">03</div>
              <div class="year-month">2023-08</div>
            </div>
            <div class="list-conent">
              <div class="title" @click="tapArticle('/medium/mediumDetail3')">
                “创新加速，智享未来”麓山病理AI创新发展高峰论坛圆满召开
              </div>
              <div class="subtitle">
                “创新加速，智享未来”麓山病理AI创新发展高峰论坛于2023年7月29日在湖南长沙正式召开，会议邀请了百余名病理及相关行业同仁参会。本次病理AI论坛以“创新加速，智享未来”为主题，聚焦“大数据智能分析、宫颈癌大规模筛查、数智化病理、人工智能”等前沿领域，旨在服务病理诊断新应用、新场景、新业态，推进病理人工智能的快速发展，助力健康中国建设，守护国人健康。
              </div>
            </div>
          </div>
          <div
            class="right"
            :style="{
              'background-image': `url(${require(`@/assets/image/medium/news-7.png`)}`,
            }"
          ></div>
        </div>
        <div class="list-item">
          <div class="left">
            <div class="tiems">
              <div class="day">09</div>
              <div class="year-month">2023-06</div>
            </div>
            <div class="list-conent">
              <div class="title" @click="tapArticle('/medium/mediumDetail4')">
                品信生物亮相2023全国细胞病理学年会，迈步非妇科细胞病理人工智能新发展
              </div>
              <div class="subtitle">
                2023年6月9日，由中华医学会、中华医学会病理学分会主办的中华医学会第二十一届全国细胞病理学会议在江城武汉成功举办！会议围绕本次会议就宫颈细胞学、非妇科脱落细胞学、细针穿刺细胞学诊断、人工智能在宫颈癌筛查中的应用等方面，邀请了来自国内外多位知名细胞病理学专家进行专题探讨，为病理同仁带来了一场精彩纷呈的细胞病理学术盛宴！
              </div>
            </div>
          </div>
          <div
            class="right"
            :style="{
              'background-image': `url(${require(`@/assets/image/medium/news-8.png`)}`,
            }"
          ></div>
        </div> -->
      </div>
      <div class="pagination-box">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="count"
          :current-page.sync="currentPage"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
import api from "../../api/api";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      focusNews: [], // 焦点新闻
      newsList: [], // 分页新闻列表
      count: 0, // 新闻列表数量
      currentPage: 1, // 当前页码
    };
  },
  created() {
    this.getFocusNews(3);
    this.getNewsList();
    this.getNewsCounts();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    // 获取焦点新闻
    getFocusNews(num) {
      api
        .getFocusNews(num)
        .then((res) => {
          console.log(res.data);
          let result = res.data.models;
          result.forEach((item) => {
            item.times = item.createTime ? item.createTime.split(" ")[0] : "";
          });
          this.focusNews = res.data.models;
        })
        .catch((error) => {});
    },
    // 分页获取文章列表
    getNewsList(page = 1) {
      api
        .getNewsByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            result.forEach((item) => {
              let times = item.createTime ? item.createTime.split(" ")[0] : "";
              let moment = times ? times.split("-") : [];
              item.year = moment[0];
              item.month = moment[1];
              item.day = moment[2];
            });
            this.newsList = result;
            console.log(this.newsList);
            // this.$nextTick(() => {
            //   new this.$wow.WOW({
            //     boxClass: "wow", // 动画元件css类（默认为wow）
            //     animateClass: "animate__animated", //动画css类（默认为animated）
            //     offset: 0, //到元素距离触发动画（当默认为0）
            //     mobile: true, //在移动设备上触发动画（默认为true）
            //     live: true, //对异步加载的内容进行操作（默认为true）
            //   }).init();
            // });
          }
        })
        .catch((error) => {});
    },
    // 获取文章总条数
    getNewsCounts() {
      api
        .getNewsCount()
        .then((res) => {
          if (res.data.code == 200) {
            this.count = res.data.models[0];
          }
        })
        .catch((error) => {});
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getNewsList(val);
    },
    tapArticle(id) {
      this.$router.push({
        path: `/MediumDetail/${id}`,
      });
    },
  },
};
</script>
    
<style lang="scss">
#medium {
  .medium-content {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    .news-header {
      padding: 3.75rem 10%;
      .line {
        width: 11.5rem;
        height: 0.375rem;
        background: #0b847b;
        margin-bottom: 1.25rem;
      }
      .news-title {
        font-size: 2.2rem;
        font-family: "SourceHanSansSC-Medium";
      }
      .news-english {
        font-size: 1.4rem;
        color: #999999;
        font-family: "OPlusSans3-Regular";
        margin-bottom: 1.25rem;
      }
      .news-box {
        display: flex;
        .new-item {
          width: 32%;
          margin-right: 2%;
          border: 1px solid #ededed;
          cursor: pointer;
          &:nth-of-type(3n) {
            margin-right: 0;
          }
          .focus-img {
            height: 18rem;
            // background-size: cover;
            // background-position: center center;
            // background-repeat: no-repeat;
            // background-size: 102%;
            img {
              width: 100%;
              height: 100%;
              border: 0;
              vertical-align: middle;
              display: inline-block;
              object-fit: cover;
              &:hover {
                animation-name: scaleDraw; /*关键帧名称*/
                animation-timing-function: ease; /*动画的速度曲线*/
                animation-duration: 2s; /*动画所花费的时间*/
              }
            }
          }

          @keyframes scaleDraw {
            /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
            0% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }

          // img {
          //   width: 100%;
          // }
          .title-box {
            padding: 1.25rem;
            .title {
              font-size: 1.2rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 0.625rem;
            }
            .time {
              color: #666666;
              font-family: "OPlusSans3-Regular";
            }
          }
        }
      }
    }
    .news-list {
      padding: 3.125rem 8%;
      .list-item {
        padding: 3.125rem 0;
        border-bottom: 1px solid #ddd;
        display: flex;
        .left {
          width: 60%;
          display: flex;
          .tiems {
            width: 20%;
            .day {
              font-size: 1.8rem;
              margin-bottom: 1rem;
              font-family: "SourceHanSansSC-Medium";
            }
            .year-month {
              font-size: 1.2rem;
              color: #666666;
              font-family: "OPlusSans3-Regular";
            }
          }
          .list-conent {
            width: 80%;
            .title {
              width: 60%;
              font-size: 1.4rem;
              margin-bottom: 1rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 2.5rem;
              cursor: pointer;
            }
            .subtitle {
              width: 70%;
              font-size: 1rem;
              color: #666666;
              font-family: "OPlusSans3-Regular";
            }
          }
        }
        .right {
          width: 40%;
          height: 20rem;
          // background-size: cover;
          // background-position: center center;
          // background-repeat: no-repeat;
          // img {
          //   width: 100%;
          // }
          img {
            width: 100%;
            height: 100%;
            border: 0;
            vertical-align: middle;
            display: inline-block;
            object-fit: cover;
            &:hover {
              animation-name: scaleDraw; /*关键帧名称*/
              animation-timing-function: ease; /*动画的速度曲线*/
              animation-duration: 2s; /*动画所花费的时间*/
            }
          }
        }

        @keyframes scaleDraw {
          /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
          0% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
    .pagination-box {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
      .el-pager li,
      .el-pagination button {
        height: 2.25rem;
        line-height: 2.25rem;
        min-width: 2.25rem;
        font-size: 0.95rem;
      }
      .el-pagination .btn-prev .el-icon,
      .el-pagination .btn-next .el-icon {
        font-size: 0.95rem;
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>